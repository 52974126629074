// extracted by mini-css-extract-plugin
export var PDF = "comunicados-module--PDF--duSWR";
export var comunicado = "comunicados-module--comunicado--pbkWC";
export var divApp = "comunicados-module--divApp--HSlMw";
export var divBtnsApp = "comunicados-module--divBtnsApp--jBhMu";
export var divImageReverse = "comunicados-module--div-image-reverse--2Qm6R";
export var divLogoApp = "comunicados-module--divLogoApp--4YOCM";
export var divPDF = "comunicados-module--divPDF--dy2YW";
export var fecha = "comunicados-module--fecha--Lx1PW";
export var iframeMaps = "comunicados-module--iframeMaps--Od8le";
export var imgBtn = "comunicados-module--imgBtn--eWmr-";
export var imgDispositivos = "comunicados-module--imgDispositivos--+534e";
export var imgLogoApp = "comunicados-module--imgLogoApp--3UJp6";
export var link = "comunicados-module--link--Owo2X";
export var mdFlexColumnReverse = "comunicados-module--md-flex-column-reverse--5N-Gv";
export var titleComunicado = "comunicados-module--titleComunicado--QE0z5";
export var titleFraccionamiento = "comunicados-module--titleFraccionamiento--rtdy9";